<template>
  <div class="insurance--wrapper">
    <div class="item_title">
      <div class="item_titleline"></div>
      响应项目
    </div>
    <div class="content">
      <search-form ref="searchForm" @refresh="$refs.table.refresh()" :statusOptions="statusOptions"></search-form>
      <p-table
        ref="table"
        row-key="id"
        :scroll="{ y: 0 }"
        extraHeight="380"
        :sourceData="getDataApi"
        :columns="columns"
      >
        <template slot="action" slot-scope="text, record">
          <a-button type="link" @click="$refs.Vform.look(record)" v-perms="'look'">查看响应信息</a-button>
        </template>
      </p-table>
    </div>
    <Vform ref="Vform"/>
  </div>
</template>

  <script>
  import searchForm from './search-form.vue'
  import { queryPageByCondition } from '@/api/TenderingBidding'
import { reStatus } from '@/views/TenderingBidding/dict'
import Vform from '@/views/bidding-enter/detail/form.vue'
import moment from 'moment'

  export default {
    name: 'Insurance',
    components: {
      searchForm,
      Vform
    },
    data () {
      return {
        statusOptions: [],
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            width: 60,
            customRender: (t, r, i) => i + 1
          },
          {
            title: '公告标题',
            dataIndex: ['announcement', 'title'],
            key: 'title'
          },
          {
            title: '项目类型',
            dataIndex: ['announcement', 'type'],
            key: 'type'
          },
          {
            title: '响应人员',
            dataIndex: 'createBy',
            key: 'createBy',
            ellipsis: true
          },
          {
            title: '响应时间',
            dataIndex: 'createTime',
            key: 'createTime',
            customRender: t => moment(t).format('YYYY-MM-DD HH:mm')
          },
          {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            customRender: (t, r) => {
              return reStatus.find(item => item.value.includes(t))?.label
            }
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            scopedSlots: { customRender: 'action' }
          }
        ],
        tableData: []
      }
    },
    computed: {
      enterpriseInfo () {
        return this.$store.getters.userInfo?.enterpriseInfoDto
      }
    },
    created () {
    },
    methods: {
      getDataApi (params) {
        const form = this.$refs.searchForm.getQuery()
        return queryPageByCondition({ ...params, ...form, enterpriseId: this.enterpriseInfo.enterpriseId })
      }
    }
  }
  </script>

  <style lang="less">
  @import url('../../common/style');
  .insurance--wrapper {
    .table-btn {
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
    }
  }
  </style>
